
import $app from '@/plugins/modules';
import { IManipulationType } from '@/modules/clinic/types';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ISimpleDataItem } from '@/modules/system/types';
import { IPatient, ITooth } from '../types';

@Component
export default class ToothManipulationsDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => null })
  readonly tooth: ITooth | undefined;

  @Prop({ default: () => null })
  readonly patient: IPatient | undefined;

  @Prop({ default: () => { } })
  readonly exclude: any;

  itemsList: Array<IManipulationType> = [];
  groups: Array<ISimpleDataItem> = [];

  filter = '';
  group = 0;

  @Watch('value')
  async onShow() {
    if (this.value) {
      try {
        this.itemsList = await $app.get('/api/clinics/manipulations');
        this.groups = await $app.get('/api/clinics/manipulations/groups');
      } catch (err) {
        $app.pushError(err);
      }
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  get items(): Array<IManipulationType> {
    return this.itemsList.filter(i => {
      return (
        i.active &&
        (!i.nvd || i.nvd.trim() === '' || !this.patient || (this.patient.patientGroups || '').trim() !== '') &&
        (
          this.filter === '' ||
          i.code.toUpperCase().includes(this.filter.toUpperCase()) ||
          i.name.toUpperCase().includes(this.filter.toUpperCase())
        )
      ) && (!this.group || this.group === 0 || i.group === this.group) &&
        (!this.exclude || !this.exclude[i.code])
    }).sort((a, b) => {
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      return 0;
    });
  }

  select(item: IManipulationType) {
    this.$emit('select', item);
  }
}
